import React, { useState, useEffect } from 'react'
import content from '../services/content.api'
import Input, { SelectInput, RadioInput, ImageUpload } from './Input'
import { MdDelete } from 'react-icons/md'
import { AiTwotoneEdit } from 'react-icons/ai'
import { CgArrowLongLeft } from 'react-icons/cg'

const ProjectInfoSelection = ({ selectedProject, setSelectedProject, setEditType }: any) => {
  const [loading, setLoading] = useState(false)
  const [contentData, setContentData] = useState<any>(null)
  const [projectInfo, setProjectInfo] = useState<any>({})
  const [changeThere, setChangeThere] = useState<boolean>(false)
  const [fields, setFields] = useState<Array<any>>([
    { type: 'text', title: 'Title', value: '', name: 'title' },
    { type: 'textarea', title: 'Description', value: '', name: 'description' },
    { type: 'image', title: 'image', value: '', name: 'image' },
  ])
  const [errors, setErrors] = useState<any>({})
  // const id = 11023
  const getContent = (id: string) => {
    if (!loading) {
      setLoading(true)
      content
        .getContentById(id)
        .then((res) => {
          setContentData(res.data)
          console.log('res.data', res.data)
          if (res.data.projectInfo) setProjectInfo({ ...res.data.projectInfo, image: res.data.projectInfo?.image?.src })
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }
  console.log(contentData)
  useEffect(() => {
    if (selectedProject !== 'Project') {
      setLoading(false)
      getContent(selectedProject.toString())
    }
  }, [selectedProject])

  // Update content to db.
  const updateContent = () => {
    content
      .updateContent(selectedProject, {
        ...contentData,
        projectInfo: { ...projectInfo, image: { src: projectInfo?.image } },
      })
      .then((res) => {
        console.log('res', res)
      })
  }

  // handle change of category input
  const handleInputChange = (e: any) => {
    setChangeThere(true)
    setErrors({})
    let temp = { ...projectInfo }
    temp[e.target.name] = e.target.value
    setProjectInfo({ ...temp })
  }
  const handleImg = (url: string) => {
    setChangeThere(true)
    setErrors({})
    let temp = { ...projectInfo }
    temp.image = url;
    setProjectInfo({ ...temp })
  }

  return (
    <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
      <div
        className="flex items-center justify-between gap-4 mb-[30px] cursor-pointer"
        onClick={() => {
          setEditType('')
          setSelectedProject('')
        }}
      >
        <div className="flex items-center justify-start gap-4">
          {' '}
          <span className="text-xl">
            <CgArrowLongLeft />
          </span>
          <span className="text-base">Back to Projects</span>
        </div>
        {changeThere && (
          <button
            className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center "
            onClick={updateContent}
          >
            {' '}
            Save
          </button>
        )}
      </div>
      {!loading && contentData && (
        <>
          <div className="justify-start text-xl flex my-4 items-center gap-4 bg-[#bec1c5] rounded-md p-3">
            {contentData?.projectName}
          </div>
          <div className="w-full">
            <div className="my-3">
              <RadioInput
                title={'Layout'}
                name={'layout'}
                value={projectInfo?.layout}
                defaultValue={projectInfo?.layout}
                options={[
                  { title: 'Layout 1', value: 'layout1' },
                  { title: 'Layout 2', value: 'layout2' },
                  { title: 'Layout 3', value: 'layout3' },
                ]}
                error={errors['layout']}
                handleChange={handleInputChange}
              />
            </div>
            {fields.map((field, index) => {
              if (field.type === 'text') {
                return (
                  <div className="my-6" key={index}>
                    <Input
                      type={field.type}
                      title={field.title}
                      defaultValue={projectInfo[field.name]}
                      name={field.name}
                      onChange={handleInputChange}
                      inputClassName={''}
                      labelClassName={'bg-[#f3f7ff]'}
                      value={projectInfo[field.name]}
                      error={errors[field.name]}
                    />
                  </div>
                )
              } else if (field.type === 'textarea') {
                return (
                  <div className="relative w-auto tracking-wider" key={index}>
                    <textarea
                      title={field.title}
                      defaultValue={projectInfo[field.name]}
                      name={field.name}
                      onChange={handleInputChange}
                      value={projectInfo[field.name]}
                      rows={4}
                      className={`text-[#383635] bg-[#00000000] autofill:!bg-[#00000000] focus:outline-none w-full p-4 px-4 border border-slate-500 tracking-wide rounded-lg font-[Sora] font-normal ${
                        errors[field.name] && 'border-red-500'
                      }`}
                    ></textarea>
                    <label
                      htmlFor={field.title}
                      className={`absolute font-[Sora] ${
                        projectInfo[field.name] ? 'top-[-12px] text-[#383635] ' : 'text-[#383635] top-[-4px]'
                      } ${
                        errors[field.name] && 'text-red-500'
                      } bg-[#f3f7ff] left-4 w-fit h-fit inset-0 px-1 capitalize transition-all ease-in-out duration-200`}
                    >
                      {field.title}
                    </label>
                    <span className="px-2 my-1 text-xs text-red-500">{errors[field.name]}</span>
                  </div>
                )
              }else if(field.type ==="image"){
               return( <div className="my-6" key={index}>
                    <ImageUpload
                     handleImg={handleImg}
                     img={projectInfo[field.name]}
                      
                    />
                  </div>)
              }
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default ProjectInfoSelection
