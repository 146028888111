import React, { useState, useRef,FC, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MdPlayLesson } from 'react-icons/md'
import { AiFillPlayCircle } from 'react-icons/ai'
import Modal from './Modal/index'
import { HelpIcon } from './Icons'
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride'
import { TourGuideContext } from '../context/TourGuideContext'

const TourGuide:FC<any> = ({}) => {
  const [hover, setHover] = useState<boolean>(false)
  const [showGuidelines, setShowGuidelines] = useState<boolean>(false) // modal toggle
  const [tab, setTab] = useState<string>('tourguide') // tabs of modal
  const tourguideRef = useRef<HTMLSpanElement>(null)
  const {run,go,next,setState, initialSteps, steps} = useContext(TourGuideContext)
  const { id } = useParams()
  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    setState((e: any) => ({
      ...e,
      run: true,
      stepIndex:1
    }))
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setState((e:any) => ({ ...e, run: false }))
    }
    else{
      // next?.()
    }
    if(!localStorage[`tour-${id}`]){
      localStorage.setItem(`tour-${id}`,"true")
    }
    // logGroup(type, data);
  }

const goto =(index:number)=>()=>{
    setShowGuidelines((e) => !e); 
    // setState((e:any)=>({...e,run:true, stepIndex:index}))
    // setTimeout(()=>{go?.(index)},200) 
    if(index!==0) setState((e:any)=>({...e,steps:[...initialSteps]?.slice(index-1),run:true}));
    else setState((e:any)=>({...e,steps:[...initialSteps],run:true}));
}
useEffect(()=>{
  if(id && !localStorage[`tour-${id}`]){
    setState((e:any) => ({ ...e, run: true }))
  }
},[id])

  return (
    <div className="relative z-[9999]">
         {/* Joyride */}
         <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          
          showProgress
          showSkipButton
          // stepIndex={stepIndex}
          scrollDuration={0}
          steps={steps}
          getHelpers={(helpers) => {
            setState((e:any) => ({ ...e, next: helpers.next, go:helpers.go }))
          }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#11A8AB',
              textColor: '#000',
            },
            buttonNext: { fontFamily: 'Sora', fontSize: '13px', paddingLeft: '15px', paddingRight: '15px' },
            buttonBack: {
              fontFamily: 'Sora',
              fontSize: '13px',
              paddingLeft: '15px',
              paddingRight: '15px',
              color: '#000',
            },
            buttonSkip: {
              fontFamily: 'Sora',
              fontSize: '13px',
              paddingLeft: '15px',
              paddingRight: '15px',
              color: '#000',
            },
            tooltipTitle: { fontFamily: 'Lexend Deca', fontSize: '14px', color: '#000' },
            tooltipContent: { fontFamily: 'Lexend Deca', fontSize: '14px', color: '#000', fontWeight: '300' },
          }}
        />
      {showGuidelines && (
        <>
          <div className="bg-[#fff] text-[#000] font-[Ubuntu] z-[999] rounded-md absolute right-10 top-0 w-[500px] flex flex-col mx-2 justify-center text-[20px] max-w-[300px]">
            <div className="w-[12px] h-[12px] rotate-[45deg] absolute right-[-4px] top-[10px] bg-white"></div>
            {/* tabs */}
            <div
              className="relative flex items-center justify-center p-2 text-lg text-white bg-white rounded-t-md "
              style={{ fontFamily: 'Sora, sans-serif' }}
            >
              {/* {tab && (
                <span
                  className="absolute bottom-0 transition-all duration-300 ease-in-out border-b-4 border-[color:var(--background-button)]"
                  style={{
                    width:
                      tab === 'tourguide'
                        ? tourguideRef?.current?.clientWidth
                        // : tab === 'connection'
                        // ? conRef?.current?.clientWidth
                        // : tab === 'question'
                        // ? quesRef?.current?.clientWidth
                        : 0,
                    left: "50%",
                    transform:'translate(-50%,0)'
                    //   tab === 'tourguide'
                    //     ? 12
                    //     : tab === 'connection'
                    //     ? (tourguideRef?.current?.clientWidth || 0) + 55
                    //     : tab === 'question'
                    //     ? (tourguideRef?.current?.clientWidth || 0) + 100
                    //    + (conRef?.current?.clientWidth || 0)
                        // : 0,
                  }}
                ></span>
              )} */}
              <span
                // className={`px-4 py-2 hover:border-b-4 rounded-b-md border-b-[color:var(--border-main)] ${
                //   tab === 'tourguide' ? 'border-b-4 border-b-[color:var(--border-main)] font-normal' : ''
                // }`}
                className={`relative px-4 py-1.5 overflow-hidden border-0 rounded-lg btn-highlight whitespace-nowrap cursor-pointer p-4 w-full text-center bg-[color:var(--background-darker)] ${
                  tab === 'tourguide' ? 'active font-normal' : ''
                }`}
                onClick={() => setTab('tourguide')}
                ref={tourguideRef}
              >
                Start tour 
              </span>
              {/* <span className="border-r border-[#fff] h-[20px] w-[1px]"> </span> */}
              {/* <span
                className={`px-4 py-1 relative overflow-hidden border-0 rounded-lg btn-highlight whitespace-nowrap cursor-pointer ${
                  tab === 'connection' ? 'active font-normal' : ''
                }`}
                onClick={() => setTab('connection')}
                ref={conRef}
              >
                Types of connections
              </span>
              <span className="border-r border-[#fff] h-[20px] w-[1px]"> </span>
              <span
                className={`px-6-4 py-1 relative overflow-hidden border-0 rounded-lg btn-highlight whitespace-nowrap cursor-pointer ${
                  tab === 'question' ? 'active font-normal' : ''
                }`}
                onClick={() => setTab('question')}
                ref={quesRef}
              >
                Guiding questions
              </span> */}
              <div className="flex justify-end" onClick={() => setShowGuidelines(false)}>
                {/* <AiOutlineClose /> */}
              </div>
            </div>

            <div className="w-full text-base font-[400] px-2 pb-2">
              {/* content */}
              {tab === 'tourguide' && (
                <div className="py-2 my-1">
                  <div className="flex items-center justify-between w-full px-2 py-1 my-2 border-b rounded-md border-slate-300">
                  <h2 className="text-lg" style={{fontFamily:"Lexend Deca"}}>Replay tour: </h2><span className='cursor-pointer hover:scale-110' onClick={goto(0)}><AiFillPlayCircle className='text-xl' /></span>
                  </div>
                  {/* <h2 className="my-3 mb-4 text-lg" style={{fontFamily:"Lexend Deca"}}>Start tour guide from following steps</h2> */}
                  <ul className='w-full !list-decimal font-[300] text-[14px]'style={{fontFamily:"Lexend Deca", listStyle:"decimal"}}>
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>1. Node themes</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(2)}><AiFillPlayCircle className='text-xl' /></span>
                    </li>
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>2. Node Group</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(3)}> <AiFillPlayCircle className='text-xl' /></span>
                    </li>
                    {/* <li className='flex items-center justify-between gap-2 py-1 border-b rounded-md border-slate-300'>
                        <span>3. Center node</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(6)}><AiFillPlayCircle className='text-xl' /></span>
                    </li> */}
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>4. Create a new connection</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(6)}><AiFillPlayCircle className='text-xl' /></span>
                    </li>
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>5. Confidence rating</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(11)}> <AiFillPlayCircle className='text-xl' /></span>
                    </li>
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>6. Add a new sub-node</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(12)}><AiFillPlayCircle className='text-xl' /></span>
                    </li>
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>7. Other features</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(14)}><AiFillPlayCircle className='text-xl' /></span>
                    </li>
                    <li className='flex items-center justify-between gap-2 px-2 py-1 border-b rounded-md border-slate-300'>
                        <span>8. Save and exit</span>
                        <span className='cursor-pointer hover:scale-110' onClick={goto(15)}><AiFillPlayCircle className='text-xl' /></span>
                    </li>
                  </ul>
                </div>
              )}
              {/* {tab === 'connection' && (
                <div className="p-2 py-2 border-b rounded-md border-slate-300">
                  <h2 className="my-3 mb-4 text-lg tracking-wider">Types of connections</h2>
                  <div className="flex items-center justify-center w-auto">
                    <div className="w-1/2 px-3 py-1 border border-[#3e4045]">Reinforcing/directly proportional</div>
                    <div className="w-1/2 px-3 py-1 border border-[#3e4045]">
                      <span className="flex items-center h-full">
                        <span className="invisible">:</span>
                        <div className="h-[1px] w-full border-b-2 border-[#2E2E2E]"></div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-auto">
                    <div className="w-1/2 px-3 py-1 border border-[#3e4045]">Balancing/indirectly proportional </div>
                    <div className="w-1/2 px-3 py-1 border border-[#3e4045]">
                      <span className="flex items-center h-full">
                        <span className="invisible">:</span>
                        <div className="h-[1px] w-full border-dashed border-b-2 border-[#ff3e3e]"></div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {tab === 'question' && (
                <div className="p-2 px-6 py-2 border-b rounded-md border-slate-300">
                  <h2 className="py-2 text-lg tracking-wider border-b rounded-md border-slate-300">Guiding questions</h2>
                  <ol className="list-decimal">
                    <li className="my-3">What do you think about the selected sub-nodes and their relationship? </li>
                    <li className="my-3">According to you, how important is the connection? </li>
                    <li className="my-3">
                      Do you think the impact of the selected sub-node is relevant to the other one? If yes, is it low,
                      medium or high?
                    </li>
                  </ol>
                </div>
              )} */}
            </div>
          </div>
          <div
            className="w-full h-full bg-[#0000008f] fixed left-0 top-0 z-[990] flex
          justify-center items-center"
            onClick={() => {
              setShowGuidelines(false)
            }}
          ></div>
        </>
      )}
      <div className="z-[999] relative tooltip" onClick={() =>{ setShowGuidelines((e) => !e);}}>
        {!showGuidelines && (
          <span className={`text-sm tooltip-text tooltip-left`}>
            {' '}
            <span className="rotate-[45deg]"></span> Tour guide{' '}
          </span>
        )}
        <MdPlayLesson className='text-2xl text-white cursor-pointer hover:scale-110' />
      </div>
    </div>
  )
}

export default TourGuide
