import React, { useState, useEffect, FC } from "react";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
type ImageUploadProps = {
    img:string;
    handleImg : (url:string)=>void;
}

const ImageUpload:FC<ImageUploadProps> = ({ img, handleImg })=> {
  const [image, setImage] = useState<{path?:string; url?:string}>({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (img) {
      console.log("img",img)
      let data = img.split("2e5xfxvehcg");
      setImage({ path: data[1], url: img });
    }
  }, [img]);

  const onSuccess = (data:any) => {
    setImage({ path: data.filePath, url: data.url });
    handleImg(data.url);
    setError("");
  };

  const onError = (err:any) => {
    setError(err.message);
    console.log(err);
  };

  return (
    <div>
      <IKContext
        publicKey={process.env.REACT_APP_IMAGEKIT_PUBLICKEY}
        urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
        authenticationEndpoint={`${process.env.REACT_APP_BACKEND_API}/image/auth`}
      >
        <div className="pr-4 mb-4">
          <div className="flex items-end justify-start">
            {image.path ? (
              <IKImage path={`tr:n-media_library_thumbnail/${image.path}`} />
            ) : null}
            <label className="border image-upload px-4 py-2 bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] rounded-md">
              {image.url ? "Change Image" : "Choose Image"}
              <IKUpload
                fileName={image.path || "abc.jpg"}
                tags={["tag1"]}
                useUniqueFileName={true}
                isPrivateFile={false}
                onSuccess={onSuccess}
                onError={onError}
              />
            </label>
            {error ?  <span className="px-2 my-1 text-xs text-red-500">{error}</span> : null}
          </div>
        </div>
      </IKContext>
    </div>
  );
}
export default ImageUpload;