import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import content from '../../services/content.api'
import { SurveyContext } from '../Survey/SurveyContext'
import ProjectInfo1 from '../../components/ProjectInfo/v_1'
import ProjectInfo2 from '../../components/ProjectInfo/v_2'
import ProjectInfo3 from '../../components/ProjectInfo/v_3'
import Navbar from '../../components/Navbar'
import Navigator from '../../components/Navigator'

const Start = () => {
  const { loading, contentData, getContent } = useContext(SurveyContext)
  const navigate = useNavigate()
  const next = () => {
    navigate('./user')
  }
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      getContent(id)
    }
  }, [id])
  useEffect(()=>{
    if(contentData?.nodes && !contentData?.projectInfo?.layout) navigate('./user')
  },[contentData?.projectInfo?.layout, contentData?.nodes])

  return (
    <>
      <Navbar />
      {contentData?.projectInfo?.layout === 'layout1' ? (
        <ProjectInfo1 projectInfo={contentData?.projectInfo} next={next} />
      ) : contentData?.projectInfo?.layout === 'layout2' ? (
        <ProjectInfo2 projectInfo={contentData?.projectInfo} next={next} />
      ) : contentData?.projectInfo?.layout === 'layout3' ? (
        <ProjectInfo3 projectInfo={contentData?.projectInfo} next={next} />
      ) : (
        <>
        </>
      )}
    </>
  )
}
export default Start
