import React from 'react'
import type { InputPropsType } from './Input.types'
const Input = (props: InputPropsType) => {
  const { type, title, defaultValue, name, onChange, inputClassName, labelClassName, value ,error, disabled, required} = props
  return (
    <div className="relative w-auto tracking-wider">
      <input
        type={type}
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        className={`text-[#383635] bg-[#00000000] autofill:!bg-[#00000000] focus:outline-none w-full p-4 px-4 border border-slate-500 tracking-wide rounded-lg font-[Sora] font-normal ${inputClassName} ${error && 'border-red-500'}`}
      ></input>
      <label
        htmlFor={title}
        className={`absolute font-[Sora] ${
          value ? 'top-[-12px] text-[#383635] ' : 'text-[#383635] top-[-4px]'
        } ${error && 'text-red-500'} ${labelClassName} left-4 w-fit h-fit inset-0 px-1 capitalize transition-all ease-in-out duration-200`}
      >
        {title}{required && <span className=""> *</span>}
      </label>
      <span className="px-2 my-1 text-xs text-red-500">{error}</span>
    </div>
  )
}
export default Input
